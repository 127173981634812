@charset "UTF-8";
/* @import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=PT+Sans:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  font-family: 'Jost', sans-serif;;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  background: linear-gradient( #222831, #277a7a, #222831);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul, ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input:focus, input:active {
  box-shadow: none !important;
}

img {
  max-width: 100%;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

section {
  padding: 3.125rem 0;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 575.98px) {
  section {
    padding: 1.25rem 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  section {
    padding: 1.875rem 0;
  }
}

.container {
  max-width: 1170px;
}

@media screen and (max-width: 575px) {
  .container {
    padding: 0px 15px;
    max-width: 100%;
    width: 100%;
  }
}

.text-mute {
  color: #787a7d !important;
}

html {
  scroll-behavior: smooth;
}

.section-title {
  text-align: center;
  font-family: 'Jost', sans-serif;;
}
.section-title span {
  background-color: rgba(66, 82, 249, 0.2);
  padding: 6px 20px;
  border-radius: 130px;
  font-weight: 600;
  font-size: 0.9rem;
  color: #4252f9;
}
.section-title h2 {
  font-weight: 600;
  margin: 15px 0px;
}
.section-title p {
  max-width: 600px;
  margin: auto;
}

/* header {
  padding-top: 2px;
}
header .head-ro {
  padding: 3px;
}
header .head-ro .logo img {
  max-width: 100px;
  padding-top: 3px;
  font-family: 'Jost', sans-serif;;
}
header .head-ro .show {
  display: block !important;
}
@media only screen and (max-width: 767px) {
  header .head-ro .nav {
    display: none;
  }
}
header .head-ro .nav ul {
  width: 100%;
}
header .head-ro .nav ul li {
  float: left;
  padding: 10px;
}
@media only screen and (max-width: 860px) {
  header .head-ro .nav ul li {
    padding: 20px 12px;
  }
}
header .head-ro .nav ul li a {
  font-weight: 550;
  color: rgb(255, 255, 255);
  font-size: 0.9rem;
}

@media only screen and (max-width: 767px) {
  header .head-ro .nav ul li {
    float: none;
    text-align: right;
  }
}
header .head-ro .nav ul .btnll {
  float: right;
}
header .head-ro .nav ul .btnll .btn {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  header .head-ro .nav ul .btnll .btn {
    width: 100%;
  }
}

.scroll-to-fixed-fixed {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: rgb(26,27,40);
}

.head-cover {
  margin-top: 0;
  padding: 10px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: #f3f4f8;
}
@media only screen and (max-width: 950px) {
  .head-cover{
    padding-left: 0;
    padding-right: 0;
  }
}

.small-menu {
  float: right;
  padding-top: 16px;
  font-size: 1.4rem;
}
.small-menu i {
  color: #ffffff;
} */

.banner-container {
  /* background-color: #082032; */
  margin: 40px 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
  /* background-image: url("./../../assets/img/bg/bg1.jpg"); */
}
.banner-container .banner-row .banner-txt {
  margin: auto;
  color: #FFF;
}
.banner-container .banner-row .banner-txt h1 {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 3rem;
  font-family: 'Jost', sans-serif;
}
.banner-container .banner-row .banner-txt p {
  margin-bottom: 10px;
  font-size: 1rem;
  letter-spacing: 0.3px;
  font-weight: 500;
  /* color: darkgray; */
}
/* .banner-container .banner-row .btn-row {
  margin-top: 50px;
} */
.banner-container .banner-row .btn-row .btn {
  width: 200px;
  margin-right: 20px;
  margin-top: 10px;
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .banner-container {
    padding: 50px 10px;
  }
  .banner-container .banner-row .btn-row .btn {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 0;
  }
  .banner-container .banner-row .banner-txt h1{
    font-size: 2.2rem;
  }
}
.clr:hover
{
background-color:#B5EAD7 !important;
color: #000000 !important;
}
.banner-container .banner-img {
  text-align: center;
  display: grid;
  place-items: center;
  padding: 50px;
}
.banner-container .banner-img .video{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 15px;
}
.banner-container .banner-img img {
  max-width: 500px;
}
@media only screen and (max-width: 767px) {
  .banner-container .banner-img {
    padding: 50px 30px 0;
  }
  .banner-container .banner-img .video{
    height: 200px;
  }
  .banner-container .banner-img img {
    width: 100%;
    margin-top: 50px;
  }
}


.predictions-cointainer {
  margin: 30px 0;
  background-color: #fff;
}

.predictions-cointainer .title {
  margin-bottom: 40px;
  text-align: center;
}
.predictions-cointainer .title span{
  color: forestgreen;
  font-weight: normal;
}
.predictions-cointainer .title p{
  font-weight: normal;
  margin: 10px 20px;
  color: #787a7d;
}
.predictions-cointainer .element{
  width: 100%;
  background-color: #ececec;
  border-radius: 7px;
  padding: 10px;
  display: flex;
  align-items: center;
}
.predictions-cointainer .element h3{
  width: 20px;
  color: #4252f9;
  font-size: 15px;
  text-align: center;
}
.predictions-cointainer .element h4{
  font-size: 15px;
  font-weight: bold;
  padding: 0 10px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.predictions-cointainer .element .values ul li{
  color: #787a7d;
  font-weight: 500;
  float: left;
  margin: 0 5px;
}
.predictions-cointainer .element .values .target{
  color: forestgreen;
}
.predictions-cointainer .btn-row {
  text-align: center;
}
.predictions-cointainer .btn-row button {
  margin: 20px 0;
  border: none;
  border-radius: 5px;
  background-color: #B5EAD7;
  color: #000000;
  padding: 10px 20px;
  
}
.predictions-cointainer .empty {
  text-align: center;
}

@media only screen and (max-width: 667px) {
  /* .predictions-cointainer .element{
    font-size: 12px;
  } */
  .predictions-cointainer .element h3{
    font-size: 14px;
  }
  .predictions-cointainer .element h4{
    width: 50%;
    font-size: 12px;
  }
  .predictions-cointainer .element .values ul li{
    font-size: 12px;
  }
}


.copy {
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%; */
  padding: 20px;
  background-color: rgb(23, 24, 32);
  color: #FFF;
}

.copy a {
  color: #FFF;
}

.copy span {
  float: right;
}

.copy span i {
  margin-right: 20px;
}
@media screen and (max-width: 560px) {
  .copy {
    padding-bottom: 40px;
  }
}

.modal-style {
  color:white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height:350px;
  border: 1px solid rgb(80, 80, 80);
  background-color: rgb(38,39,52);
  border-radius:30px;
  box-shadow: 24px;
  text-align:center;
  padding-bottom: 20px;
}
.modal-btn {
  padding: 10px;
  border: 0;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(38,39,52);
}
@media screen and (max-width: 670px ) {
  .modal-style {
    width: 300px;
    height: 400px;
  }
  .modal-btn {
    padding: 8px;
  }
}
